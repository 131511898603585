import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
import * as buffer from "buffer";
// 1. Get projectId
const projectId = 'cd408d5867ef9021136e7ebbbd0d92c8'

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://mainnet.infura.io/v3/7eb6c263fce046d6b855698644f54767'
}
/*
const sepoliaNetwork = {
  chainId: 11155111, // Replace with the actual chain ID for Sepolia network
  name: 'Sepolia Network',
  currency: 'SPN', // Replace with the currency symbol for Sepolia network
  explorerUrl: 'https://sepolia.etherscan.io', // Replace with the explorer URL for Sepolia network
  rpcUrl: 'https://sepolia.infura.io/v3/98c23bd6843c4c81ba288f92d98d6a66' // Replace with the RPC URL for Sepolia network
}
*/
/*
const avalanchelive = {
  chainId: 43114,
  name: 'Avalanche Network C-Chain',
  currency: 'AVAX',
  explorerUrl: 'https://snowtrace.io/',
  rpcUrl: 'https://avalanche-mainnet.infura.io/v3/98c23bd6843c4c81ba288f92d98d6a66'
}
*/


const metadata = {
  name: 'Big3',
  description: 'Big3 Claim',
  url: 'https://big3.com', // origin must match your domain & subdomain
  icons: ['https://mint.big3.com/assets/new/images/logo.png']
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: false, // true by default
  rpcUrl: '', // used for the Coinbase SDK
  defaultChainId: 1, // used for the Coinbase SDK
})

// 5. Create a Web3Modal instance
window.modal = createWeb3Modal({
  ethersConfig,
  //  chains: [mainnet, sepoliaNetwork, avalanchelive],
  chains: [mainnet],
  projectId,
  enableAnalytics: false // Optional - defaults to your Cloud configuration
})
window.Buffer = buffer.Buffer;

function App() {
  return ''
}

export default App;
